<template>
  <div class="ps_pages_Css">
    <div class="wp animsition"></div>
    <div id="particles-js"></div>

    <!-- 头部 -->
    <div class="headBox">
      <div>
        <vue-seamless-scroll
          :class-option="optionSingleHeightTime"
          :data="studentList"
          class="warp"
        >
          <ul class="item">
            <li v-for="item in studentList" :key="item.UserID">
              <div class="li-content">
                <p>
                  <avatar
                    class="topAvater"
                    :imagesrc="item.photo"
                    :fontSize="2.5"
                  ></avatar>
                </p>
                <div class="userName">{{ item.userName }}</div>
                <div class="li-box">
                  <div class="box1">
                    <h4><span>德</span></h4>
                    <div class="pic"></div>
                  </div>
                  <div class="box2">
                    <h4><span>智</span></h4>
                    <div class="pic"></div>
                  </div>
                  <div class="box3">
                    <h4><span>体</span></h4>
                    <div class="pic"></div>
                  </div>
                  <div class="box4">
                    <h4><span>美</span></h4>
                    <div class="pic"></div>
                  </div>
                  <div class="box5">
                    <h4><span>劳</span></h4>
                    <div class="pic"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- 总进度百分比 -->
    <!-- <div class="totalProcess">
      <div class="totalPcecharts" id="totalEchart"></div>
    </div> -->
    <!-- 班级名称 -->
    <div class="classNameBox">
      <div class="topTip">批量生成评语</div>
      <div class="bottomTip">{{ className }}</div>
      <div class="bottomNum">(共{{ studentList.length }}人)</div>
    </div>
    <!-- 圆环 -->
    <div class="circleCenter">
      <div class="fnClass" v-if="!activate">
        <div class="fnItem" @click="activateFn">
          <div class="huanBgItem"></div>
          <div class="startFn">{{ activateText }}</div>
        </div>
      </div>

      <div id="circleProcessId" v-show="activate"></div>
    </div>
    <!-- 悬浮机器人 -->
    <div class="floatRobotL">
      <img class="robotImg" src="./images/robot1.png" alt="" />
    </div>
    <div class="floatRobotR">
      <img class="robotImg" src="./images/robot2.png" alt="" />
    </div>
    <div class="floatNowUser" v-if="activate">
      <div class="nowUserItem">
        <div class="NowText">当前</div>
        <p>
          <avatar
            class="userAvaterItem"
            :imagesrc="noFinishStudentList[0].photo"
            :fontSize="4"
          ></avatar>
          <!-- <img class="userAvaterItem" :src="noFinishStudentList[0].photo" /> -->
        </p>
        <div class="userNameNow">{{ noFinishStudentList[0].userName }}</div>
      </div>
    </div>
    <!-- 文字 -->
    <div class="textCircle" v-if="activate">
      <div
        v-for="(item, index) in randomItems"
        :key="index"
        class="itemText"
        :style="getItemStyless(index)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 未完成列表 -->
    <div class="noFinishBox">
      <div class="box">
        <div class="tit">
          <span>待评价列表</span>
          <div class="numCircleBox">
            <div class="imgQiu">
              <div class="numberBox">{{ noFinishStudentList.length }}</div>
              <img class="circleYuan" src="./images/cicleYuan.png" alt="" />
            </div>
          </div>
        </div>

        <div class="boxnav" style="overflow:hidden ;">
          <!--  :class="{ roll: studentList.length > 5 && activate }" -->
          <div class="adduser" style="height: 60vh;">
            <template v-if="noFinishStudentList.length > 0">
              <div
                class="studentItem"
                v-for="item in noFinishStudentList"
                :key="item.UserID"
              >
                <img src="./images/userBg.png" />
                <div class="main_bottom_t_l_title">{{ className }}</div>
                <div class="avaterBox">
                  <avatar
                    class="avaterImg"
                    :imagesrc="item.photo"
                    :fontSize="2"
                  ></avatar>
                  <!-- <img class="avaterImg" :src="item.img" /> -->
                </div>
                <div class="centerInfo">
                  <div class="userName">{{ item.userName }}</div>
                  <div
                    class="makeTip"
                    style="flex: 1;display: flex;align-items: center;"
                  >
                    <div>进度 :</div>
                    <div style="flex: 1;">
                      <el-progress
                        :percentage="item.percentage"
                        :format="format"
                        color="#409EFF"
                      ></el-progress>
                    </div>
                  </div>
                </div>
                <div class="rightBox">
                  <img class="makeImg" src="./images/make.gif" />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="noText">
                暂无
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 已完成列表 -->
    <div class="FinishBox">
      <div class="box">
        <div class="tit">
          <span>已完成列表</span>
          <div class="numCircleBox">
            <div class="imgQiu">
              <div class="numberBox">{{ finishStudentList.length }}</div>
              <img class="circleYuan" src="./images/cicleYuan.png" alt="" />
            </div>
          </div>
        </div>
        <div class="boxnav" style="overflow:hidden ;">
          <!--   :class="{ roll: finishStudentList.length > 5 && activate }" -->
          <div class="adduser" style="height: 60vh;">
            <vue-seamless-scroll
              :class-option="optionSingleFinish"
              :data="finishStudentList"
              class="finishBox"
              ref="scrollFinish"
            >
              <div
                class="studentItem"
                v-for="item in finishStudentList"
                :key="item.UserID"
              >
                <img src="./images/userBg.png" />
                <div class="main_bottom_t_l_title">{{ className }}</div>
                <div class="avaterBox">
                  <avatar
                    class="avaterImg"
                    :imagesrc="item.photo"
                    :fontSize="2"
                  ></avatar>
                </div>
                <div class="centerInfo">
                  <div class="userName">{{ item.userName }}</div>
                  <div
                    class="makeTip"
                    style="flex: 1;display: flex;align-items: center;"
                  >
                    <div>进度 :</div>
                    <div style="flex: 1;">
                      <el-progress
                        :percentage="100"
                        :format="format"
                        color="#67C23A"
                      ></el-progress>
                    </div>
                  </div>
                </div>
                <div class="rightBox">
                  <img class="makeImg" src="./images/success.gif" />
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
    <!-- 输入框 -->
    <div class="bottomIpt">
      <div class="aiImg">
        <img class="aiItem" src="./images/ai.png" />
        <img class="animImg" src="./images/lbx.png" />
      </div>
      <div class="iptBox">
        <!-- <div class="textBox" id="typewriter" :class="{ activeText: activate }">
          {{ activate ? showText : '' }}
        </div> -->
        <span id="typed" class="ipt-text" style="white-space: pre-wrap;"></span>
      </div>
      <!-- <div class="rightNum">
        <div class="topTip">累计已生成</div>
        <div class="centerNum">{{ this.totalAllNum }}</div>
        <div class="bottomTip">条评价</div>
      </div> -->
    </div>
    <!-- 暂停 stopFn -->
    <div class="endFn" @click="stopFnClick" v-if="activate || stop">
      <div class="huanBgItem"></div>
      <div class="stopTxt">{{ stopText }}</div>
    </div>
    <!-- 结束 -->
    <!-- <div class="endFn" @click="endFnClick" v-if="activate || stop || endStatus">
      <div class="huanBgItem"></div>
      <div class="stopTxt">{{ endText }}</div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue' // 引入 Vue
import * as echarts from 'echarts'
import particlesJson from './particles.json'
let totalEchartRef = null
import CodePenVueComponent from './js/script.js'
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
  GetClassUserList,
  GetStudentComment,
  BaiDuAiSummary,
} from '@/api/index.js'
import moment from 'moment'
import Avatar from '../../components/Avatar.vue'
import Typed from 'typed.js'

const handred = 100
let point = 80
let sum = (point / handred) * 100

export default {
  components: { Avatar, vueSeamlessScroll },
  data() {
    return {
      moment: moment,
      startDate: '', //开始日期
      endDate: '', //结束日期
      activate: false, //是否启动
      activateText: '开启',
      stop: false,
      showCircle: true, //显示圆环
      stopText: '暂停',
      endText: '结束',
      endStatus: false,
      totalAllNum: 4545, //生成的总数量
      radius: 250, // 圆的半径
      randomItems: [],
      classID: localStorage.getItem('classID'), //当前的班级id
      className: localStorage.getItem('className'), //当前的班级名称
      //标签
      tabList: [
        '爱长辈',
        '爱锻炼',
        '爱父母',
        '爱国，国家大事',
        '爱护花草',
        '爱劳动',
        '爱粮食',
        '爱绿护绿',
        '爱心，文明、尊重',
        '爱心章',
        '爱学习',
        '爱艺术',
        '安排时间',
        '安全意识强',
        '摆齐桌椅',
        '班级体，服务',
        '班集体，争光',
        '帮助，公益',
        '保持班级卫生',
        '保护动物',
        '保质期药物',
        '报警电话',
        '背诵古诗',
        '比赛',
        '不弄丢东西',
        '不跑跳不打闹',
        '不使用一次性用品',
        '才艺章',
        '菜品制作',
        '餐具整理',
        '常识章',
        '乘车',
        '穿戴整洁',
        '穿戴整理',
        '穿戴整齐',
        '穿鞋鞋带',
        '穿着着装',
        '创作、立体美术作品',
        '创作立体美术作品',
        '创作美术作品',
        '挫折，身心健康',
        '打招呼',
        '带齐资料',
        '待客做客礼仪',
        '德',
        '德备用',
        '德有关数据导入',
        '电雷雨',
        '调整目标',
        '定制环保计划并实践',
        '毒品硫酸',
        '读写姿势',
        '独立处理',
        '多样性、欣赏',
        '防溺水安全',
        '防震',
        '房间整理',
        '分工合作',
        '分类整理',
        '分享榜样',
        '负责章',
        '复习',
        '改错',
        '歌唱能力',
        '个人卫生',
        '公共卫生',
        '公益，帮助',
        '公益劳动',
        '沟通章',
        '古诗文诵读，秉德养正',
        '关注环境问题',
        '光盘',
        '国歌、校歌',
        '国家大事',
        '国旗，国徽，国家，爱国',
        '合作',
        '合作清扫',
        '合作有序',
        '合作章',
        '核心价值观',
        '贺卡',
        '护隐私',
        '花样跳绳',
        '环保章',
        '环境设施',
        '汇总，汇报',
        '活动秩序',
        '火电',
        '火灾',
        '火灾自救',
        '积极回答问题',
        '及时改错',
        '急救办法',
        '集会礼仪',
        '计划安排',
        '记作业',
        '家务，家人',
        '假期实践活动',
        '捡拾垃圾',
        '讲故事',
        '讲究卫生',
        '交通安全',
        '交通乘车',
        '交往相处',
        '接待',
        '节水',
        '紧急自救',
        '就餐礼仪',
        '课间活动安全',
        '课前准备',
        '课堂发言',
        '课堂发言讨论',
        '课堂礼仪',
        '口头表达',
        '垃圾分类',
        '篮球',
        '劳',
        '劳备用',
        '劳动安排',
        '劳动学习',
        '劳有关数据导入',
        '雷锋日',
        '礼貌用语',
        '礼仪称呼',
        '礼仪技能',
        '礼仪章',
        '理解，尊重，感激',
        '理想章',
        '两操',
        '两操规范',
        '矛盾',
        '煤气中毒',
        '美',
        '美备用',
        '美术作业',
        '美有关数据导入',
        '灭火器使用',
        '陌生人敏感',
        '能自律',
        '溺水自救',
        '排球',
        '品德',
        '乒乓球',
        '评价',
        '欺凌违法',
        '骑车头盔',
        '勤俭节约',
        '清洁工具',
        '清扫保持',
        '请假离校',
        '全面发展',
        '认真改错',
        '认真倾听',
        '认真听讲',
        '上交寒假美术作品',
        '上交寒假作业',
        '设计',
        '设计标志',
        '社会礼仪',
        '社会实践',
        '社团',
        '审美、才艺',
        '生气国歌',
        '十个一、艺术才能',
        '十个一、艺术才能、校外',
        '实践章',
        '食品',
        '食物',
        '使用环保用品',
        '收拾整洁',
        '书面表达',
        '树立目标',
        '双语阅读',
        '搜集信息',
        '探究性学习，发明，创造',
        '探究章',
        '陶笛',
        '体',
        '体备用',
        '体测',
        '体有关数据导入',
        '天气衣物',
        '跳绳',
        '团结，帮助',
        '完成作业',
        '卫生保健',
        '卫生习惯优',
        '卫生整洁',
        '文明就餐',
        '文明通行',
        '问候礼仪',
        '午餐饮食',
        '物品整齐',
        '系鞋带',
        '项目化',
        '孝敬，家人，家务，照顾',
        '欣赏',
        '欣赏、作品',
        '欣赏美',
        '新劳动技能',
        '学具',
        '学习活动',
        '学习优点',
        '学习章',
        '学业成绩',
        '烟花爆竹',
        '研学',
        '演唱',
        '演讲，口语表达',
        '衣物整理',
        '艺 术 节',
        '艺术测评、画展',
        '艺术活动',
        '艺术节',
        '意外警察',
        '音乐、美术课',
        '饮食就餐',
        '用品摆放',
        '用品整理',
        '用眼习惯',
        '游泳危险',
        '有计划',
        '有理想',
        '有实践',
        '有特长',
        '有勇气',
        '有阅读',
        '右行礼让',
        '右行玩耍',
        '阅读',
        '运动',
        '运动章',
        '在家',
        '赞美别人',
        '展示汇报',
        '整洁',
        '整理',
        '整理收纳',
        '整理书包',
        '整理准备',
        '志愿活动',
        '制定计划',
        '智',
        '智备用',
        '智有关数据导入',
        '中外美术作品',
        '中外艺术作品',
        '种植观察',
        '种植植物',
        '主动捡拾班级垃圾',
        '主动问好',
        '注意力集中',
        '注重形象',
        '桌面干净',
        '姿态良好',
        '自豪感，民族精神，国际视野',
        '自己区域整洁',
        '自理章',
        '自律章',
        '自主学习',
        '自尊自爱，不伤害',
        '足球',
        '组织章',
        '尊敬师长，团结，帮助',
        '尊重，关心，理解',
        '作品多样性',
        '做好小事',
      ],
      //打字效果
      typeOptions: {
        strings: [],
        typeSpeed: 50, // 打印速度
        startDelay: 300, // 开始之前的延迟300毫秒
        loop: true, // 是否循环
        smartBackspace: true,
        fadeOut: true,
      },
      typed: null, //打字效果
      proCess: null, //自动累加进度定时器
      showText:
        '上学期综合评价总结如下：<br /><br />你在这学期展现出了令人赞叹的全方位素养，无论是学科成绩还是艺术素养，你都交出了满意的答卷。特别值得一提的是，你在整个学期中都保持着全勤，这体现了你的高度责任心和良好的学习习惯，真的很棒，希望你能继续保持。<br /><br />在艺术素质测评中，你的表现尤为突出。无论是基础指标、学业指标，还是校外学习部分，你都取得了满分的佳绩，全面超越了班级其他同学。这充分展示了你在艺术领域的深厚底蕴和出色才能，也反映了你对艺术学习的热情和投入。老师为你的优异表现感到由衷的高兴，期待你在艺术道路上能走得更远，飞得更高。<br /><br />在期末学业成绩检测中，你同样展现出了强大的实力。语文、数学、英语和科学四门学科，你每一科都取得了卓越的成绩，总分更是高居班级榜首。这充分证明了你扎实的学习基础、全面的知识结构和出色的应试能力。老师为你的优异成绩感到骄傲，也相信这背后凝聚了你无数的努力和汗水。<br /><br />当然，学习的道路永无止境。老师希望你能够继续保持谦虚好学的态度，不断挑战自己，追求卓越。在未来的学习生活中，更加注重培养自己的创新思维和实践能力，努力成为一个全面发展、综合素质过硬的优秀学生。加油！',
      //学生数组
      studentList: [],
      //进度
      optionTotalPc: {
        title: [
          {
            text: sum + '%',
            x: 'center',
            y: 'center',
            textStyle: {
              fontWeight: 'normal',
              color: '#fff',
              fontSize: '25',
            },
          },
        ],
        series: [
          //最外层圆环
          {
            name: '背景',
            type: 'pie',
            radius: '80%',
            startAngle: 179.7,
            data: [{ value: 1, name: '背景' }],
            itemStyle: {
              color: '#0e1f2e',
              borderWidth: 1,
              borderColor: '#4FADFD',
            },
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
          },
          {
            name: 'circle',
            type: 'pie',
            zlevel: 1,
            radius: ['50%', '55%'],
            startAngle: 179.7,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: point,
                name: '占比',
                itemStyle: {
                  color: '#08bdff',
                },
              },
              {
                name: '剩余',
                value: handred - point,
                itemStyle: {
                  color: '#0d3a50',
                },
              },
            ],
          },
          {
            name: '我是白线',
            type: 'pie',
            //   radius: ['50%', '55%'],
            radius: ['60%', '50%'],
            silent: true,
            clockwise: true,
            startAngle: 180, // 白线位置
            z: 2,
            zlevel: 2,
            label: {
              normal: {
                position: 'center',
              },
            },
            data: [
              {
                value: 0.2,
                name: '',
                itemStyle: {
                  normal: {
                    color: '#fff',
                  },
                },
              },
              {
                value: 100 - 0.2,
                name: '',
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: 'transparent',
                  },
                },
              },
            ],
          },
        ],
      },
    }
  },
  computed: {
    //已经完成学生列表
    finishStudentList() {
      let newList = []
      this.studentList.map((obj) => {
        if (obj.cType != 'SYS') {
          newList.push(obj)
        }
      })
      return newList
    },
    //未完成学生列表
    noFinishStudentList() {
      let newList = []
      this.studentList.map((obj) => {
        if (obj.cType == 'SYS') {
          newList.push(obj)
        }
      })
      if (newList.length <= 0) {
        if (this.typed) {
          this.typed.stop()
        }

        this.stop = false
        this.endStatus = false

        this.endText = '结束'
        this.stopText = '暂停'
        this.activate = false
      }
      return newList
    },
    //滚动
    optionSingleHeightTime() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: this.studentList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        switchDelay: 10,
        loop: true,
      }
    },
    //滚动
    optionSingleFinish() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: this.finishStudentList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        switchDelay: 10,
        loop: true,
      }
    },
  },
  beforeDestroy() {
    if (this.typed) {
      this.typed.destroy()
    }
  },
  mounted() {
    require('particles.js')
    particlesJS('particles-js', particlesJson)
    // new Vue({
    //   render: (h) => h(CodePenVueComponent),
    // }).$mount('#circleProcessId')

    // this.totalEchartFn(this.optionTotalPc)
    //获取学期时间
    this.getTimeFn()
    //获取班级列表
    this.getClassList() // 班级学生成员
    //获取随机50条评价
    this.randomItems = this.getRandomItems(this.tabList, 50)
    //初始暂停滚动
    this.$nextTick(() => {
      this.$refs.scrollFinish._stopMove()
    })

    // CodePenVueComponent.mounted('#circleProcessId')
  },
  methods: {
    typeWriter(text, i) {
      const typewriter = document.getElementById('typewriter')
      if (i < text.length) {
        typewriter.innerHTML += text.charAt(i)
        i++
        setTimeout(() => {
          typeWriter(text, i)
        }, 100) // 设置打字间隔时间，单位为毫秒
      }
    },
    //进度状态
    format(percentage) {
      if (percentage >= 100) {
        return '已完成'
      } else if (percentage > 0) {
        return '生成中'
      } else {
        return '待生成'
      }
    },
    // 获取人员列表
    async getClassList() {
      if (this.classID && this.classID != '0') {
        await GetClassUserList({
          ClassID: this.classID,
          rType: 1,
        }).then(async (res) => {
          if (res.data.length > 0) {
            this.studentList = await Promise.all(
              res.data.map(async (obj) => {
                let comObj = await this.getStudentComment(obj.UserID)
                let percentage = 0
                if (comObj.cType == 'SYS') {
                  percentage = 0
                } else {
                  percentage = 100
                }
                return {
                  percentage,
                  ...comObj,
                  ...obj,
                }
              })
            )
            this.$nextTick(() => {
              // this.comProcess()
            })
          } else {
            this.studentList = []
          }
        })
      }
    },
    //处理学期
    getTimeFn() {
      let nowMonth = moment().month() + 1

      if ([2, 3, 4, 5, 6, 7, 8].includes(nowMonth)) {
        this.startDate =
          moment()
            .add(0, 'Y')
            .format('YYYY') + '-02-01'
        this.endDate =
          moment()
            .add(0, 'Y')
            .format('YYYY') + '-07-31'
      } else if ([9, 10, 11, 12].includes(nowMonth)) {
        this.startDate =
          moment()
            .add(0, 'Y')
            .format('YYYY') + '-09-01'
        this.endDate =
          moment()
            .add(+1, 'Y')
            .format('YYYY') + '-01-31'
      } else if (nowMonth == 1) {
        this.startDate =
          moment()
            .add(-1, 'Y')
            .format('YYYY') + '-09-01'
        this.endDate =
          moment()
            .add(0, 'Y')
            .format('YYYY') + '-01-31'
      }
      console.log('获取最后的时间', this.startDate, this.endDate)
    },
    //获取学生已有评论数据
    async getStudentComment(StudentID) {
      let obj = {
        StudentID,
        ClassID: this.classID,
        startDate: this.startDate,
        endDate: this.endDate,
        iType: 3,
      }
      try {
        const res = await GetStudentComment(obj)

        return res.data
      } catch (error) {
        return null
      }
    },
    //遍历数组调用接口
    async forEachFn() {
      this.addProcess()
      for (let item of this.noFinishStudentList) {
        await this.baiDuAiSummary(item.sid, item.UserID)
      }
      if (this.noFinishStudentList.length == 0) {
        this.activateText = '完成'
      }
    },
    //自动生成评语接口
    async baiDuAiSummary(sid, UserID) {
      this.addProcess()
      this.randomItems = this.getRandomItems(this.tabList, 50)
      if (!this.stop) {
        await BaiDuAiSummary({ sid }).then((res) => {
          if (res.data.Ai) {
            let index = this.studentList.findIndex(
              (obj) => obj.UserID == UserID
            )
            this.studentList[index].cType = 'AI'
            this.spliceCom(res.data.Summary)
          }
        })
      }
    },
    //分割评语
    spliceCom(str) {
      this.typeOptions.strings = []
      if (this.typed) {
        this.typed.stop()
      }
      this.typeOptions.strings = str.split(/<br \/><br \/>/)
      this.$nextTick(() => {
        this.typed = new Typed('#typed', this.typeOptions)
        if (!this.stop) {
          this.typed.start()
        } else {
          this.typed.stop()
        }
      })
    },
    //获取当前时间
    getCurrentDate() {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      return formattedDate
    },

    //计算进度
    comProcess() {
      let allLength = this.studentList.length
      let hasLength = this.finishStudentList.length
      let sum = (hasLength / allLength) * 100
      console.log('打印数据1111111', allLength, hasLength, sum)
      let listArr = [
        {
          value: hasLength,
          name: '占比',
          itemStyle: {
            color: '#08bdff',
          },
        },
        {
          name: '剩余',
          value: allLength - hasLength,
          itemStyle: {
            color: '#0d3a50',
          },
        },
      ]
      this.$nextTick(() => {
        this.optionTotalPc.title[0].text = sum + '%'
        this.optionTotalPc.series[2].data = listArr
        console.log('88888', this.optionTotalPc)
      })
    },
    // 重新绘制函数
    redraw() {
      // 重新设置配置对象
      this.myChart.setOption(newOption)
    },
    //启动暂停
    activateFn() {
      if (this.stop) {
        return
      }
      if (this.noFinishStudentList.length <= 0) {
        return
      }
      if (this.endStatus) {
        return
      }
      let num = 3
      this.activateText = num
      let timer = setInterval(() => {
        num -= 1
        console.log('num', num)
        if (num == 0) {
          this.activateText = 'Go!'
        } else if (num > 0) {
          this.activateText = num
        } else {
          clearInterval(timer)
          this.activate = true
          if (this.activate) {
            new Vue({
              render: (h) => h(CodePenVueComponent),
            }).$mount('#circleProcessId')
            //分割评语内容
            this.spliceCom(this.showText)
            this.$refs.scrollFinish._startMove()
            //遍历请求接口
            this.forEachFn()
          }
        }
      }, 1000)
    },
    //结束
    endFnClick() {
      console.log('点击了结束')
      if (this.endText == '重新') {
        // this.$message({
        //   message: '返回成功',
        //   type: 'success',
        // })
        setTimeout(() => {
          this.activate = true
          this.stop = false
          this.endStatus = false
          this.activateText = '开启'
          this.endText = '结束'
          this.stopText = '暂停'
        }, 1000)
        return
      }
      this.activate = false
      this.stop = false
      this.showCircle = false
      this.endStatus = true
      this.activateText = '结束'
      this.endText = '重新'
    },
    //累加进度(未完成第一条)
    addProcess() {
      if (this.proCess) {
        clearInterval(this.proCess)
        this.proCess = null
      }
      this.proCess = setInterval(() => {
        this.noFinishStudentList[0].percentage += 5
        if (this.noFinishStudentList[0].percentage >= 90) {
          clearInterval(this.proCess)
        }
      }, 1000)
    },
    //暂停
    stopFnClick() {
      if (this.stopText == '暂停') {
        this.typed.stop()
        this.$refs.scrollFinish._stopMove()
        clearInterval(this.proCess)
        this.activate = false
        this.stop = true
        this.stopText = '继续'
        this.activateText = '暂停'
      } else {
        this.$refs.scrollFinish._startMove()
        this.typed.start()
        this.activate = true
        this.stop = false
        this.stopText = '暂停'
        this.forEachFn()
      }
    },
    //总进度
    async totalEchartFn() {
      totalEchartRef = echarts.init(document.getElementById('totalEchart'))
      let value = 0.6104 // 绘制图表
      totalEchartRef.setOption(this.optionTotalPc)
    },
    Pie() {
      console.log('调用了')
      let dataArr = []
      for (var i = 0; i < 150; i++) {
        if (i % 2 === 0) {
          dataArr.push({
            name: (i + 1).toString(),
            value: 50,
            itemStyle: {
              normal: {
                color: '#00AFFF',
                borderWidth: 0,
                borderColor: 'rgba(0,0,0,0)',
              },
            },
          })
        } else {
          dataArr.push({
            name: (i + 1).toString(),
            value: 100,
            itemStyle: {
              normal: {
                color: 'rgba(0,0,0,0)',
                borderWidth: 0,
                borderColor: 'rgba(0,0,0,0)',
              },
            },
          })
        }
      }
      return dataArr
    },
    // 随机显示50条内容的函数
    getRandomItems(arr, num) {
      const result = []
      const len = arr.length
      const indices = new Set()

      while (indices.size < num) {
        const index = Math.floor(Math.random() * len)
        indices.add(index)
      }

      indices.forEach((index) => {
        result.push(arr[index])
      })

      return result
    },
    //获取随机颜色样式
    getItemStyless(index) {
      const angle = (2 * Math.PI) / this.randomItems.length
      const x = this.radius * Math.cos(angle * index) + 500 / 2 - 25
      const y = this.radius * Math.sin(angle * index) + 500 / 2 - 25
      const offsetX = Math.cos(angle * index) * 25
      const offsetY = Math.sin(angle * index) * 25
      var letters = '0123456789ABCDEF'
      var color = '#'
      do {
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)]
        }
      } while (color === '#FFFFFF' || color === '#000000')

      return {
        top: y - offsetY + 'px',
        left: x - offsetX + 'px',
        color,
        fontSize: Math.floor(Math.random() * 6 + 22) + 'px',
        animationDuration: Math.floor(Math.random() * 5 + 1) + 's',
      }
    },
    //数字累加
    addNumber() {
      Object.keys(this.numForm).map((key) => {
        form[key] //获得属性对应的值，可以进行其它处理
        animateNumber(form[key], key)
      })
    },
    accumulate() {
      this.totalAllNum = 0
      this.totalAllNum += increment
      if (this.totalAllNum > targetValue) {
        this.totalAllNum = 0
        setTimeout(accumulate, 3000) // 等待3秒后继续累加
      } else {
        console.log(this.totalAllNum) // 输出累加后的值
        setTimeout(accumulate, interval) // 继续累加
      }
    },

    //累加器
    animateNumbers(target) {
      let current = 0
      if (target === 0) return

      const startTime = performance.now()
      const duration = 2000 // 动画持续时间为2秒

      const updateNumber = (timestamp) => {
        const elapsed = timestamp - startTime
        current = Math.ceil((target * elapsed) / duration)

        if (elapsed < duration) {
          this.totalAllNum = current
          requestAnimationFrame(updateNumber)
        } else {
          this.totalAllNum = target
          setTimeout(() => {
            this.animateNumbers(target)
          }, 5000)
        }
      }

      requestAnimationFrame(updateNumber)
    },
  },
}
</script>

<style scoped>
@import url('./css/reset.css');
@import url('./css/scanboardLogin.css');
/* @import url('./css/animsition.css'); */
@font-face {
  font-family: '汉仪铸字超然体';
  src: url('./fonts/汉仪铸字超然体.TTF');
}
.ps_pages_Css {
  width: 100vw;
  height: 100vh;
}
.circleCenter {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9999999 !important;
}
.fnClass {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  z-index: 999;
  border: 1px solid rgba(7, 118, 181, 0.5);
  box-shadow: inset 0 0 10px rgba(7, 118, 181, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: toBig 3s infinite linear;
}
@keyframes toBig {
  0% {
    width: 200px;
    height: 200px;
    opacity: 1;
  }
  50% {
    width: 350px;
    height: 350px;
    opacity: 0.9;
  }

  100% {
    width: 500px;
    height: 500px;
    opacity: 0.8;
  }
}
.fnClass .fnItem {
  width: 200px;
  height: 200px;
  position: relative;
  border: 1px solid rgba(7, 118, 181, 0.5);
  box-shadow: inset 0 0 10px rgba(7, 118, 181, 0.4);
  border-radius: 50%;
  animation: all 0.5;
}
.fnClass .fnItem .startFn {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  transform: translate(-50%, -50%);
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: showTxt 5s infinite linear;
}
@keyframes showTxt {
  0% {
    opacity: 1;
    background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  }
  50% {
    opacity: 0.5;

    background-image: -webkit-linear-gradient(bottom, #5289b8, #e6e5e5);
  }
  100% {
    opacity: 1;

    background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  }
}
.fnClass .fnItem .huanBgItem {
  background-image: url(./images/huanbg.png);
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: 100%;
  animation: leftRight 5s infinite linear;
}
@keyframes leftRight {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-36deg);
  }
  20% {
    transform: rotate(-72deg);
  }
  30% {
    transform: rotate(-36deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-30deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(50deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#circleProcessId {
  width: 100%;
  height: 100%;
}
.textCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  z-index: 999;
  border: 1px solid rgba(7, 118, 181, 0.5);
  box-shadow: inset 0 0 10px rgba(7, 118, 181, 0.4);
  border-radius: 50%;
  /* background-color: lightblue; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemText {
  position: absolute;
  /* width: 50px;
  height: 50px; */
  /* font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  animation: centenAm 5s infinite linear;
}
@keyframes centenAm {
  from {
    transform: translate(0 0);
  }
  to {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
  }
}
.noFinishBox {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
}
.FinishBox {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
}
.box {
  border: 1px solid rgba(7, 118, 181, 0.5);
  box-shadow: inset 0 0 10px rgba(7, 118, 181, 0.4);
  margin-bottom: 12px;
  position: relative;
}
.tit {
  padding: 10px 10px 10px 25px;
  border-bottom: 1px solid rgba(7, 118, 181, 0.7);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  color: #4ba6e0;
}
.tit span {
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tit:before,
.tit01:before {
  position: absolute;
  content: '';
  width: 6px;
  height: 6px;
  background: rgba(22, 214, 255, 0.9);
  box-shadow: 0 0 5px rgba(22, 214, 255, 0.9);
  border-radius: 10px;
  left: 10px;
  top: 18px;
}

.tit:after,
.box:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
}
.box:before {
  top: -1px;
}
.headBox {
  width: 80vw;
  height: 130px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}
.headBox:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
  box-sizing: border-box;
}
.stopFn {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 20px;
  left: 350px;
  z-index: 9999999;
  animation: toShowAms 2s linear;
  border: 1px solid rgba(7, 118, 181, 0.5);
  box-shadow: inset 0 0 10px rgba(7, 118, 181, 0.4);
  border-radius: 50%;
}
@keyframes toShowAms {
  from {
    bottom: -50px;
    left: 350px;
    scale: 0.4;
  }
  to {
    bottom: 20px;
    left: 350px;
    scale: 1;
  }
}
.stopFn .huanBgItem {
  background-image: url(./images/huanbg.png);
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: 100%;
  animation: leftRight 5s infinite linear;
}
.stopFn .stopTxt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  font-size: 18px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.endFn {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 999999999;
  bottom: 20px;
  right: 350px;
  animation: toShowAms 2s linear;
  border: 1px solid rgba(7, 118, 181, 0.5);
  box-shadow: inset 0 0 10px rgba(7, 118, 181, 0.4);
  border-radius: 50%;
}
@keyframes toShowAms {
  from {
    bottom: -50px;
    right: 350px;
    scale: 0.4;
  }
  to {
    bottom: 20px;
    right: 350px;
    scale: 1;
  }
}
.endFn .huanBgItem {
  background-image: url(./images/huanbg.png);
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: 100%;
  animation: leftRight 5s infinite linear;
}
.endFn .stopTxt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  font-size: 18px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bottomIpt {
  width: 50vw;
  height: 100px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}
.bottomIpt:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
}
.iptBox {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.ipt-text {
  width: auto;
  height: 7em;
  display: flex;
  align-items: center;
  border-right: 1px solid transparent;
  font-family: Consolas, Monaco;
  word-break: normal;
  /* overflow: hidden; */
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(227, 223, 223, 0.5);
  font-size: 1em;
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.iptBox:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
}
.textBox {
  /* width: 100%; */
  width: auto;

  height: 1.25em;
  border-right: 1px solid transparent;
  font-family: Consolas, Monaco;
  word-break: normal;
  /* overflow: hidden; */
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(227, 223, 223, 0.5);
  font-size: 1em;
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.activeText {
  animation: typing 5s steps(30, end) infinite,
    blink-caret 0.75s step-end infinite;
}
/* 打印效果 */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 55em;
  }
}

/* 光标闪动 */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: currentColor;
  }
}

.aiImg {
  position: relative;
}
.animImg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  animation: rotateAn 3s infinite linear;
}
.rightNum {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.rightNum .topTip {
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rightNum .centerNum {
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-weight: 600;
}
.rightNum .bottomTip {
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes rotateAn {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.aiItem {
  width: 100px;
  height: 100px;
}
.adduser {
  padding: 5px;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.studentItem {
  height: 50px;
  width: 100%;
  margin: 25px 0;
  /* margin-bottom: 30px; */
  /* background-color: rgba(75, 166, 224, 0.2); */
  display: flex;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
  position: relative;
  /* border: 1px solid red; */
}
/* .studentItem:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
} */
.studentItem:nth-child(1) {
  margin-left: 0;
}
.studentItem img {
  position: absolute;
  width: 380px;
  height: 70px;
}
.studentItem .main_bottom_t_l_title {
  z-index: 800;
  position: absolute;
  font-size: 14px;
  top: -6px;
  left: 40px;
  color: white;
}
.studentItem .centerInfo {
  flex: 1;
  height: 100%;
  margin-left: 60px;
  display: flex;
  align-items: flex-end;
}
.studentItem .centerInfo .userName {
  color: #fff;
  font-family: '汉仪铸字超然体';
  letter-spacing: 0.2rem;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.studentItem .centerInfo .makeTip {
  margin-left: 20px;
  color: #fff;
}
.studentItem .rightBox {
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 5px;
  width: 40px;
  height: 100%;
}
.studentItem .rightBox .makeImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.8;
}
.avaterBox {
  position: absolute;
  font-size: 16px;
  top: 18px;
  left: 18px;
}
.avaterBox .avaterImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #076ead;
}

.leftAvater {
  width: 50px;
  height: 50px;
  /* border: 1px solid red; */
  position: relative;
  border-radius: 50%;
}
.leftAvater:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
}
.centerInfo {
  flex: 1;
  height: 100%;
  /* border: 1px solid red; */
}
/* .centerInfo:before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background: linear-gradient(to right, #076ead, #4ba6e0, #076ead);
  box-shadow: 0 0 5px rgba(131, 189, 227, 1);
  opacity: 0.6;
} */
.avaterImg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.numCircleBox {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  /* border: 1px solid red; */
  width: 40px;
  height: 40px;
}
.numCircleBox .imgQiu {
  position: relative;
  width: 40px;
  height: 40px;
}
.numCircleBox .imgQiu .numberBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  fcolor: #fff;
  font-family: '汉仪铸字超然体';

  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.numCircleBox .imgQiu .circleYuan {
  width: 40px;
  height: 40px;
}
.floatRobotL {
  position: absolute;
  top: 60%;
  left: 425px;
  transform: translateY(-50%);
  animation: upDown 8s infinite linear;
}
.floatNowUser {
  position: absolute;
  top: 35%;
  left: 460px;
  transform: translateY(-50%);
  /* border: 1px solid red; */
  width: 260px;
  height: 140px;
}
.floatNowUser .nowUserItem {
  opacity: 0;
  position: relative;
  width: 100%;
  height: 100%;
  background: url(./images/studentbg.png) no-repeat center;
  background-size: contain;
  overflow: hidden;
  animation: show5 1s linear forwards 1.5s;
}
.floatNowUser .nowUserItem .userAvaterItem {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
}
.floatNowUser .nowUserItem .NowText {
  position: absolute;
  top: -5px;
  left: 50%;
  height: 30px;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  /* font-family: '汉仪铸字超然体'; */
  z-index: 66;
  letter-spacing: 5px;
  background-image: -webkit-linear-gradient(bottom, #f53913, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.floatNowUser .nowUserItem .userNameNow {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  /* font-weight: 600; */
  fcolor: #fff;
  font-family: '汉仪铸字超然体';
  letter-spacing: 10px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes upDown {
  0% {
    transform: translateY(-45%);
  }
  25% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(-45%);
  }
  75% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-45%);
  }
}
.floatRobotR {
  position: absolute;
  top: 70%;
  right: 425px;
  transform: translateY(-50%);
  animation: upDown 6s infinite linear;
}
.robotImg {
  width: 300px;
  height: 400px;
  z-index: 99999999999999;
  opacity: 0.3;
}
.headBox .ul-box {
  width: 80vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  /* animation: move 10s linear infinite 5s; */
}
.headBox .ul-box ul {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}
.headBox li {
  height: 100%;
  margin: 0 20px;
  background: url(./images/di.png) no-repeat center bottom;
  background-size: 50%;
  /* border: 1px solid blue; */
}
.headBox .li-content {
  opacity: 0;
  position: relative;
  width: 270px;
  height: 60%;
  background: url(./images/studentbg.png) no-repeat center;
  background-size: contain;
  overflow: hidden;
  animation: show5 1s linear forwards 1.5s;
  /* border: 1px solid red; */
}
.headBox .li-content p {
  width: 100%;
  height: 20%;
  text-align: center;
  color: #fff;
  display: table;
}
.headBox .li-content p span {
  display: table-cell;
  vertical-align: middle;
}
.headBox .li-content p:nth-of-type(1) {
  margin-top: 10%;
}
.headBox .li-content p:nth-of-type(2) {
  font-family: '液晶数字字体';
  font-size: 2em;
}
.headBox .li-content .li-box {
  position: absolute;
  width: 70%;
  height: 80%;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}
.headBox .li-box .box1,
.headBox .li-content .li-box .box2,
.headBox .li-content .li-box .box3,
.headBox .li-content .li-box .box4,
.headBox .li-content .li-box .box5 {
  width: 15%;
  height: 50%;
  position: absolute;
}
.headBox .li-content .li-box .box1 h4,
.headBox .li-content .li-box .box2 h4,
.headBox .li-content .li-box .box3 h4,
.headBox .li-content .li-box .box4 h4,
.headBox .li-content .li-box .box5 h4 {
  height: 50%;
  color: #fff;
  font-size: 0.7rem;
  font-weight: normal;
  display: table;
}
.headBox .li-content .li-box .box1 h4 span,
.headBox .li-content .li-box .box2 h4 span,
.headBox .li-content .li-box .box3 h4 span,
.headBox .li-content .li-box .box4 h4 span,
.headBox .li-content .li-box .box5 h4 span {
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
}
.headBox .li-content .li-box .box1 h4 span {
  color: #5b7ad7;
}
.headBox .li-content .li-box .box2 h4 span {
  color: #91cc75;
}
.headBox .li-content .li-box .box3 h4 span {
  color: #fac858;
}
.headBox .li-content .li-box .box4 h4 span {
  color: #91cc75;
}
.headBox .li-content .li-box .box5 h4 span {
  color: #73c0de;
}
.headBox .li-content .li-box .box1 .pic,
.headBox .li-content .li-box .box2 .pic,
.headBox .li-content .li-box .box3 .pic,
.headBox .li-content .li-box .box4 .pic,
.headBox .li-content .li-box .box5 .pic {
  width: 100%;
  height: 50%;
  color: #fff;
  font-size: 0.7rem;
}
.headBox .li-content .li-box .box1 .pic img,
.headBox .li-content .li-box .box2 .pic img,
.headBox .li-content .li-box .box3 .pic img,
.headBox .li-content .li-box .box4 .pic img {
  width: 1rem;
  vertical-align: bottom;
  margin-right: 5%;
}
.headBox .li-content .li-box .box1 {
  left: 12px;
  top: 5px;
  font-size: 20px;
  color: #5b7ad7 !important;
}
.headBox .li-content .li-box .box2 {
  left: 52%;
  top: -10px;
  transform: translateX(-50%);
}
.headBox .li-content .li-box .box3 {
  right: 4px;
  top: 5px;
}
.headBox .li-content .li-box .box4 {
  right: 12px;
  bottom: -5px;
}
.headBox .li-content .li-box .box5 {
  left: 19px;
  bottom: -5px;
}
.headBox li {
  transform: translateY(100%);
  animation: di2 1s linear forwards;
  transform: translateY(100%);
}
/* .headBox ul li:nth-of-type(2) {
  transform: translateY(100%);
} */
/* .headBox ul li:nth-of-type(3) {
  transform: translateY(100%);
} */
@keyframes show1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes show2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes show3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}
@keyframes show4 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
  }
}
@keyframes come1 {
  0% {
    opacity: 0;
    left: 40%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes come2 {
  0% {
    opacity: 0;
    right: 40%;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}
@keyframes come3 {
  0% {
    opacity: 0;
    top: 40%;
  }
  100% {
    opacity: 1;
    top: 15%;
  }
}
@keyframes come4 {
  0% {
    opacity: 0;
    bottom: 40%;
  }
  100% {
    opacity: 1;
    bottom: 10%;
  }
}
@keyframes rotate1 {
  0% {
    transform: translateY(-50%) rotateY(0);
  }
  100% {
    transform: translateY(-50%) rotateY(360deg);
  }
}
@keyframes inner1 {
  0% {
    left: 40%;
    opacity: 0;
  }
  100% {
    left: 10%;
    opacity: 1;
  }
}
@keyframes inner2 {
  0% {
    right: 40%;
    opacity: 0;
  }
  100% {
    right: 10%;
    opacity: 1;
  }
}
@keyframes arrow1 {
  0% {
    opacity: 0.2;
    left: 10%;
  }
  100% {
    opacity: 1;
    left: 15%;
  }
}
@keyframes arrow2 {
  0% {
    opacity: 0.2;
    right: 10%;
  }
  100% {
    opacity: 1;
    right: 15%;
  }
}
@keyframes meter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0) rotate(0);
  }
  40% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.8) rotate(360deg);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.8) rotate(540deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1) rotate(540deg);
  }
}
@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes sale {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 60px, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes animate1 {
  0% {
    left: -50%;
  }
  100% {
    left: 50%;
  }
}
@keyframes animate2 {
  0% {
    left: 150%;
  }
  100% {
    left: 50%;
  }
}
@keyframes di2 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes show5 {
  0% {
    opacity: 0;
    transform: translateY(100%) scale(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.topAvater {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0.6;
}
.li-content .userName {
  position: absolute;
  top: 70%;
  left: 52%;
  transform: translateX(-50%);

  color: #fff;
  font-family: '汉仪铸字超然体';
  letter-spacing: 10px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.totalProcess {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.totalPcecharts {
  width: 100%;
  height: 100%;
}

.classNameBox {
  width: 160px;
  height: 130px;
  position: absolute;
  top: 20px;
  left: 20px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.classNameBox .topTip {
  color: #fff;
  font-family: '汉仪铸字超然体';
  font-size: 25px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.classNameBox .bottomTip {
  color: #fff;
  font-family: '汉仪铸字超然体';
  font-size: 20px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.classNameBox .bottomNum {
  color: #fff;
  font-family: '汉仪铸字超然体';
  font-size: 20px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.roll {
  animation: row 20s linear infinite;
}
@keyframes row {
  0% {
  }
  100% {
    transform: translateY(-100%);
  }
}
.boxnav:hover {
  animation-play-state: paused;
}

.warp {
  height: 6.5rem;
  width: 80vw;
  margin: 0 auto;
  overflow: hidden;
  /* border: 1px solid red; */
}
.warp ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  height: 6.5rem;
}
.warp .item {
  display: flex;
}
.warp li {
  display: block;
  /* width: 300px !important; */
  height: 100%;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.finishBox {
  height: 60vh;
  margin: 0 auto;
  overflow: hidden;
}
.noText {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: '汉仪铸字超然体';
  font-size: 30px;
  background-image: -webkit-linear-gradient(bottom, #3aa4ff, #e6e5e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 10px;
}
</style>
